export const basicColors = {
  white: "#FFFFFF",
  primary: {
    light: "#65CCFF",
    main: "#1CB4FF",
    dark: "#1A8AC1",
  },
  grey: {
    800: "#717171",
    900: "#191B1A",
  },
};
