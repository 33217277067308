import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { IconArrowUp } from "icons/ArrowUp";

export const ToTopButton = () => {
  const [scrollPosition, setScrollPosition] = useState(null);

  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPosition(window.scrollY);
    });
  }, []);
  return (
    <IconButton
      size="large"
      sx={{
        position: "fixed",
        bottom: 24,
        right: 24,
        backgroundColor: "grey.900",
        borderColor: "grey.700",
        opacity: scrollPosition > 100 ? 1 : 0,
        visibility: scrollPosition > 100 ? "visible" : "hidden",
        transition: "opacity ease 0.3s",
        zIndex: 10,
      }}
      onClick={handleButtonClick}
    >
      <IconArrowUp
        sx={{
          color: "primary.main",
        }}
      />
    </IconButton>
  );
};