import React from "react";
import { SvgIcon } from "@mui/material";

export const IconHabr = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 82 27"
      {...rest}
    >
      <path d="M16.7102 0.103027V10.8179H6.46239V0.103027H0.964844V26.2297H6.46239V15.7283H16.7102V26.2297H22.2078V0.103027H16.7102Z" />
      <path d="M34.9379 7.21533C30.0809 7.21533 27.3188 9.96411 26.8117 13.1666H31.6954C31.989 12.1925 32.9231 11.2718 34.7111 11.2718C36.4724 11.2718 37.7668 12.1658 37.7668 13.9005V14.7678H33.7237C28.9734 14.7678 26.1045 16.9561 26.1045 20.7724C26.1045 24.5086 28.9867 26.6702 32.5761 26.6702C34.978 26.6702 36.9261 25.7362 38.0737 24.2284V26.2432H42.9307V14.0873C42.9174 9.55045 39.7283 7.21533 34.9379 7.21533ZM34.0039 22.6005C32.3893 22.6005 31.3485 21.8132 31.3485 20.5189C31.3485 19.1845 32.4961 18.3972 34.1106 18.3972H37.7801V19.2245C37.7801 21.3862 36.0188 22.6005 34.0039 22.6005Z" />
      <path d="M57.7153 7.2153C55.1933 7.2153 53.472 8.21607 52.4979 9.5104V0.0898438H47.2539V26.2165H52.2844V24.0949C53.1117 25.536 54.9398 26.6435 57.5685 26.6435C62.132 26.6435 65.7614 23.0541 65.7614 16.9428C65.7748 10.738 62.1453 7.2153 57.7153 7.2153ZM56.461 22.1334C54.0191 22.1334 52.4045 20.1586 52.4045 17.0228V16.876C52.4045 13.7536 54.0191 11.7388 56.461 11.7388C58.9028 11.7388 60.4907 13.7937 60.4907 16.9561C60.4907 20.1185 58.9028 22.1334 56.461 22.1334Z" />
      <path d="M79.7319 7.57536C77.6102 7.57536 75.5687 8.36263 74.3411 10.5243V7.64207H69.3105V26.2296H74.5546V16.8091C74.5546 14.1537 76.2759 12.5258 79.4383 12.5258H81.0262V7.56201L79.7319 7.57536Z" />
    </SvgIcon>
  );
};