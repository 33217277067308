import React from "react";
import { SvgIcon } from "@mui/material";

export const IconChevronUp = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 20 11"
      {...rest}
    >
      <path d="M2 8.7929L9.64644 1.14645C9.84171 0.951184 10.1583 0.951185 10.3536 1.14645L18 8.79289" stroke="currentColor" strokeWidth="2" strokeLinecap="square" fill="none" />
    </SvgIcon>
  );
};