import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Stack,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { checkEmail } from "utils/check";
import { FileInput } from "./FileInput";
import { SubscriptionToCaptcha } from "./SubscriptionToCaptcha";

const formFields = [
  {
    Component: TextField,
    type: "text",
    label: "ФИО",
    placeholder: "Иванов Иван Иванович",
    name: "fio",
    attrs: {
      inputProps: {
        maxLength: 255,
      },
    },
  },
  {
    Component: TextField,
    type: "text",
    label: "Телефон",
    placeholder: "+7 (999) 999-99-99",
    name: "phone",
    validate: (data) => {
      if (!data.telegram && !data.phone) {
        return "Необходимо указать телеграм или номер телефона";
      }

      return;
    },
    attrs: {
      inputProps: {
        maxLength: 255,
      },
    },
  },
  {
    Component: TextField,
    type: "text",
    label: "Telegram",
    name: "telegram",
    validate: (data) => {
      if (!data.telegram && !data.phone) {
        return "Необходимо указать телеграм или номер телефона";
      }

      return;
    },
    attrs: {
      inputProps: {
        maxLength: 1024,
      },
    },
  },
  {
    Component: TextField,
    type: "text",
    label: "Почта",
    name: "mail",
    attrs: {
      inputProps: {
        maxLength: 255,
      },
    },
    validate: (data) => {
      if (data.mail && !checkEmail(data.mail)) {
        return "Введите корректную почту";
      }

      return;
    },
  },
  {
    Component: TextField,
    type: "text",
    label: "Ссылка на резюме",
    name: "link",
    attrs: {
      inputProps: {
        maxLength: 1024,
      },
    },
    validate: (data) => {
      if ((data.phone || data.telegram) && !data.file && !data.link) {
        return "Необходимо загрузить файл или указать ссылку на резюме";
      }

      return;
    },
  },
  {
    Component: TextField,
    type: "text",
    label: "Сопроводительное письмо",
    name: "letter",
    attrs: {
      multiline: true,
      rows: 4,
      inputProps: {
        maxLength: 1500,
      },
    },
  },
  {
    Component: FileInput,
    type: "file",
    name: "file",
    validate: (data) => {
      if ((data.phone || data.telegram) && !data.link && !data.file) {
        return "Необходимо загрузить файл или указать ссылку на резюме";
      }

      return;
    },
  },
];

export const ContactForm = ({vacancy}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    
    const errors = formFields.reduce((acc, cur) => {
      if (cur.validate) {
        return ({
          ...acc,
          [cur.name]: cur.validate(formValues),
        });
      }

      return acc;
    }, {});

    if (Object.values(errors).filter(Boolean).length > 0) {
      setFormErrors(errors);
    } else {
      if  (captchaStatus !== "success" || !captchaToken) {
        errors.captcha = "Подтвердите, что вы не робот";
        setFormErrors(errors);
      } else if (!formValues.agreement) {
        errors.agreement = "Ошибка";
        setFormErrors(errors);
      } else {
        setIsFetching(true);
        try {
          const data = await fetch(
            "/api/save_huntflow_applicant",
            {
              method: "POST",
              body: JSON.stringify({
                ...formValues,
                token: captchaToken,
                vacancy,
              }),
              headers: { "Content-Type": "application/json" },
            }
          );
          const result = await data.json();
          if (result.ok) {
            setFormValues({});
            enqueueSnackbar("Ваша заявка успешно отправлена", { variant: "success" });
          } else if (result.error) {
            enqueueSnackbar(result.error, { variant: "error" });
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Произошла неизвестная ошибка, попробуйте позже", { variant: "error" });
        } finally {
          setIsFetching(false);
        }
      }
    }
  }

  const handleFieldChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (["phone", "telegram"].includes(name)) {
      setFormErrors((prev) => ({
        ...prev,
        phone: "",
        telegram: "",
      }));
    }

    if (["link", "file"].includes(name)) {
      setFormErrors((prev) => ({
        ...prev,
        link: "",
        file: "",
      }));
    }
  }

  return (
    <Box
      component="form"
      noValidate
      sx={{
        maxWidth: 450,
        mx: "auto",
      }}
      onSubmit={onSubmit}
    >
        <Stack
          spacing={6}
        >
          {formFields.map(({
            Component,
            attrs,
            label,
            name,
            placeholder,
          }) => {
            return (
              <Component
                {...attrs}
                key={name}
                name={name}
                placeholder={placeholder}
                value={formValues[name] || ""}
                error={Boolean(formErrors[name])}
                helperText={formErrors[name]}
                label={label}
                onChange={(event) => {
                  handleFieldChange(name, event.target.value);
                }}
              />
          );
          })}
          <SubscriptionToCaptcha
            error={formErrors.captcha}
            onStatusChange={status => {
              setFormErrors({});
              setCaptchaStatus(status);
            }}
            onTokenChange={token => setCaptchaToken(token)}
          />
        </Stack>
        <Box
          sx={{
            ml: "-9px",
            mt: 12,
          }}
        >
          <Stack
            sx={{ m: 3 }}
            direction="row"
            alignItems="center"
          >
            <Checkbox
              value="agreement"
              name="agreement"
              checked={formValues.agreement || false}
              sx={{
                color: formErrors.agreement ? "error.main" : "common.white",
              }}
              onChange={(event) => {
                handleFieldChange("agreement", event.target.checked);
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: 1.4,
                ml: 1,
                color: formErrors.agreement ? "error.main" : "",
              }}
            >
              Нажимая кнопку «Отправить отклик», я даю согласие на обработку предоставленных мною персональных данных на условиях настоящего
              {" "}
              <Box
                component="a"
                href="/files/Согласие_на_обработку_персональных_данных_.pdf"
                target="_blank"
                sx={{ color: "inherit" }}
              >
                согласия на обработку персональных данных.
              </Box>
            </Typography>
          </Stack>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{
            width: "100%",
            mt: 4,
          }}
          disabled={isFetching}
        >
          {isFetching
            ? <CircularProgress />
            : "Отправить отклик"}
        </Button>
    </Box>
  );
};