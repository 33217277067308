import React from "react";

export const IconHealthy = (rest) => {
  return (
    <svg width="84" height="80" viewBox="0 0 84 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M62.2168 23.7075C56.6842 18.2695 47.7942 18.0331 41.9779 23.0928C36.1616 18.0331 27.2717 18.2695 21.7391 23.7075C18.9491 26.4502 17.3887 30.1385 17.3887 34.0161C17.3887 37.8936 18.9491 41.582 21.7391 44.3246L21.7864 44.3719L41.3159 63.6178C41.5051 63.8069 41.7415 63.9015 41.9779 63.9015C42.2144 63.9015 42.4508 63.8069 42.6399 63.6178L62.2168 44.3246C65.0067 41.582 66.5672 37.8936 66.5672 34.0161C66.5672 30.1385 65.0067 26.4502 62.2168 23.7075ZM60.8927 43.0006L41.9779 61.6317L23.0631 43.0006L23.0158 42.9533C20.6042 40.5417 19.2802 37.3735 19.2802 34.0161C19.2802 30.6114 20.6042 27.4432 23.0631 25.0315C28.1228 20.0664 36.3035 20.0664 41.3159 25.0315C41.5996 25.3153 41.9779 25.3626 42.3562 25.2207C42.4981 25.1734 42.5927 25.0788 42.6872 24.9843C47.6997 20.0664 55.8803 20.0664 60.94 24.9843C63.3517 27.3959 64.723 30.5641 64.723 33.9688C64.6757 37.4207 63.3517 40.589 60.8927 43.0006Z" fill="#1CB4FF" stroke="#1CB4FF" strokeWidth="2"/>
      <path d="M53.7048 36.948H49.5909L47.4629 32.7394C47.2738 32.4084 46.9428 32.2193 46.6118 32.2193C46.2335 32.2193 45.9025 32.4557 45.7606 32.7867L42.3559 40.5418L38.8567 30.9425C38.7148 30.5642 38.3838 30.3278 38.0055 30.3278C37.5799 30.2805 37.2489 30.5169 37.1071 30.8952L34.4117 36.948H25.4272C24.907 36.948 24.4814 37.3735 24.4814 37.8937C24.4814 38.4139 24.907 38.8394 25.4272 38.8394H35.0737C35.452 38.8394 35.783 38.603 35.9249 38.272L37.8637 33.827L41.3629 43.4263C41.5048 43.8046 41.8358 44.041 42.2141 44.041C42.2141 44.041 42.2141 44.041 42.2614 44.041C42.6397 44.041 42.9707 43.8046 43.1125 43.4736L46.6591 35.3875L48.1722 38.3193C48.3141 38.6503 48.6451 38.8394 49.0234 38.8394H53.7521C54.2723 38.8394 54.6979 38.4139 54.6979 37.8937C54.6979 37.3735 54.225 36.948 53.7048 36.948Z" fill="#1CB4FF" stroke="#1CB4FF" strokeWidth="2"/>
    </svg>
  );
}
