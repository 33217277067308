import React from "react";
import { SvgIcon } from "@mui/material";

export const IconArrowUp = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 10 18"
      {...rest}
    >
      <path d="M5.63074 0.744351C5.27171 0.41855 4.72829 0.41855 4.36926 0.744351L0.790313 3.99205C0.611295 4.1545 0.5 4.38072 0.5 4.62978C0.5 4.87883 0.611295 5.10506 0.790313 5.26751C1.14935 5.59331 1.69276 5.59331 2.05179 5.26751L4.07895 3.42798V16.6179C4.07895 16.8617 4.1721 17.0958 4.35874 17.2652C4.54097 17.4306 4.77378 17.5 5 17.5C5.22622 17.5 5.45903 17.4306 5.64126 17.2652C5.8279 17.0958 5.92105 16.8617 5.92105 16.6179V3.42798L7.94821 5.26751C8.13486 5.43688 8.38537 5.51186 8.57895 5.51186C8.77252 5.51186 9.02304 5.43688 9.20969 5.26751C9.38871 5.10506 9.5 4.87883 9.5 4.62978C9.5 4.38072 9.38871 4.1545 9.20969 3.99205L5.63074 0.744351Z" />
    </SvgIcon>
  );
};