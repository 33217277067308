import React from "react";
import { SvgIcon } from "@mui/material";

export const IconTelegram = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 33 28"
      {...rest}
    >
      <path d="M30.4791 4.34088L25.3618 22.7956C25.121 23.6648 24.0073 24.1126 23.0942 23.6999L16.6224 20.7763L13.562 25.1574C12.7292 26.3514 10.6221 25.8334 10.6221 24.4374V19.556C10.6221 19.1784 10.8027 18.8185 11.1138 18.5551L23.7263 8.01954C23.7163 7.88784 23.5557 7.77371 23.3952 7.87029L8.34443 17.0362L3.28737 14.7535C2.10338 14.2179 2.15355 12.7166 3.37768 12.2513L28.3118 2.74299C29.5058 2.28645 30.7902 3.22587 30.4791 4.34088Z" />
    </SvgIcon>
  );
};