export const MuiButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: 800,
      fontSize: 18,
      lineHeight: 1.5,
      borderRadius: 32,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary?.contrastText,
      // padding: theme.spacing(1, 6),
      textTransform: "none",
      padding: "1rem",
      "@media (min-width:600px)": {
        padding: "1rem 2rem",
      }
    }),
    outlined: ({ theme }) => ({
      border: "2px solid",
      borderColor: theme.palette.primary.main,

      "&:hover": {
        border: "2px solid",
        borderColor: theme.palette.primary.light,
      },

      "&:focus": {
        borderColor: theme.palette.primary.dark,
      },

      "&.Mui-disabled": {
        borderColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      },
    }),
    contained: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,

      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },

      "&:active": {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    }),
  },
};
