export const MuiIconButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: "thin solid",
      borderColor: theme.palette.common.white,

      "& .MuiSvgIcon-root": {
        fontSize: 20,
      },
    }),
  },
};