import React from "react";
import { Stack } from "@mui/material";
import { Header } from "./components/Header";
import { Intro } from "./components/Intro";
import { About } from "./components/About";
import { Mission } from "./components/Mission";
import { Values } from "./components/Values";
import { Buns } from "./components/Buns";
import { Jobs } from "./components/Jobs";
import { Internships } from "./components/Internships";
import { Footer } from "./components/Footer";
import { ToTopButton } from "./components/ToTopButton";
import { ContactUs } from "./components/ContactUs";

export const MainPage = () => {
  return (
    <>
      <Header />
      <Stack
        component="main"
        alignItems="center"
        sx={{
          background: `
            url("/images/background/circles.svg") top right / contain no-repeat,
            url(/images/background/lines-2.svg) 0 18% / 25% no-repeat,
            url("/images/background/lines-1.svg") 100% 57%/20% no-repeat,
            url("/images/background/bg.svg") top right / auto
          `,
        }}
      >
        <Intro />
        <About />
        <Mission />
        <Values />
        <Buns />
        <Jobs />
        <ContactUs
          sx={{
            mb: 20,
          }}
        />
        <Internships />
        <Footer />
        <ToTopButton />
      </Stack>
    </>
  );
};