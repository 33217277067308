import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "theme";

export const Mission = () => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        width: "100%",
        background: `
          url("/images/background/circle.svg") 0 26% / contain no-repeat
        `,
      }}
    >
      <Stack
        component="section"
        alignItems="center"
        direction={isMiddleScreen ? "column" : "row"}
        sx={{
          maxWidth: "1072px",
          width: "100%",
          paddingTop: 27,
          pb: 27,
          px: 4,
          mx: "auto",
          background: `
            url("/images/background/circle.svg") 0 26% / 50% auto no-repeat
          `,
          [theme.breakpoints.down("xs")]: {
            pt: 8,
            pb: 9,
          }
        }}
        spacing={15}
      >
        <Box
          sx={{
            width: 270,
            height: 320,
            borderRadius: 8,
            backgroundColor: "primary.main",
            flexGrow: 1,
          }}
        >
          <img
            width={270}
            alt="Олег Бартунов"
            src="/images/oleg-bartunov.png"
          />
        </Box>
        <Box
          sx={{
            flexShrink: 20,
          }}
        >
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: 28,
              mb: 3,
              [theme.breakpoints.down("xs")]: {
                fontSize: 18,
              },
            }}
          >
            Секрет успеха Postgres Pro — опыт и знания наших разработчиков. Мы любим сложные и интересные задачи, воплощаем новое и определяем развитие отрасли СУБД в России. Присоединяйтесь и развивайте карьеру в команде лучших!
          </Typography>
          <Typography
            sx={{ fontWeight: 800 }}
          >
            Олег Бартунов,
          </Typography>
          <Typography>
            Сооснователь и генеральный директор
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};