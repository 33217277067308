import { basicColors } from "../basic-colors";

const PRIMARY_FONT = ["Manrope", "sans-serif"].join(",");

export const themeOptions = {
  spacing: 4,
  palette: {
    background: {
      default: basicColors.grey["900"],
      paper: basicColors.white,
    },
    primary: {
      main: basicColors.primary.main,
      contrastText: basicColors.white,
    },
    text: {
      primary: basicColors.white,
    },
    grey: {
      800: basicColors.grey[800],
      900: basicColors.grey[900],
    },
  },
  typography: {
    fontFamily: PRIMARY_FONT,
    h1: {
      fontSize: 36,
      fontWeight: 800,
      textAlign: "center",
      "@media (min-width:600px)": {
        fontSize: 60,
      }
    },
    h2: {
      fontSize: 32,
      fontWeight: 800,
      marginBottom: 80,
      textAlign: "center",
      "@media (min-width:600px)": {
        fontSize: 60,
      }
    }, 
    body2: {
      fontSize: 16,
      lineHeight: 1.2,
      fontWeight: 600,
      "@media (min-width:600px)": {
        fontSize: 30,
      }
    },
  }
};
