import React from "react";
import { SvgIcon } from "@mui/material";

export const IconMail = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 45 28"
      {...rest}
    >
      <path d="M5.4864 2.625C4.88538 2.625 4.33372 2.78299 3.862 3.04304L21.3163 16.3786C22.2386 17.0837 23.3546 17.0836 24.2784 16.3786L41.7646 3.02911C41.2945 2.77168 40.738 2.625 40.1402 2.625H5.4864ZM2.60389 4.43652C2.49797 4.70853 2.42871 4.99632 2.42871 5.30048V23.137C2.42871 24.6192 3.79247 25.8125 5.4864 25.8125H40.1402C41.8341 25.8125 43.1979 24.6192 43.1979 23.137V5.30048C43.1979 4.99632 43.1286 4.70853 43.0227 4.43652L25.6162 17.7303C23.9945 18.9679 21.5999 18.9557 19.9786 17.7165L2.60389 4.43652Z" />
    </SvgIcon>
  );
};