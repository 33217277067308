import React, { useState, useRef } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Box, Stack, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Masonry from "react-responsive-masonry";
import { Swiper, SwiperSlide } from "swiper/react";
import { theme } from "theme";
import { IconIron } from "../icons/Iron";
import { IconEducation } from "../icons/Education";
import { IconShedule } from "../icons/Shedule";
import { IconHealthy } from "../icons/Healthy";
import { IconSport } from "../icons/Sport";
import { IconImprovement } from "../icons/Improvement";

SwiperCore.use([Pagination]);


const items = [
  [
    {
      id: 1,
      Icon: IconIron,
      title: "Мощное железо",
      text: "Выдаем компьютеры, ноутбуки, мониторы и другую технику.",
    },
    {
      id: 2,
      Icon: IconEducation,
      title: "Обучение",
      text: "Оплачиваем внешние обучающие программы и компенсируем затраты на посещение конференций.",
    },
    {
      id: 3,
      Icon: IconShedule,
      title: "Удобный график",
      text: "Гибкое начало рабочего дня. Работа удаленно, в офисе или в гибридном формате.",
    },
  ],
  [
    {
      id: 4,
      Icon: IconHealthy,
      title: "Забота о здоровье",
      text: "ДМС с первого дня работы для вас, после испытательного срока — для родственника.",
    },
    {
      id: 5,
      Icon: IconSport,
      title: "Спорт",
      text: "Компенсируем затраты на тренажерный зал, спортивные гаджеты и экипировку.",
    },
    {
      id: 6,
      Icon: IconImprovement,
      title: "Обустройство рабочего места",
      text: "Компенсируем затраты на обустройство удаленного рабочего места: дополнительное освещение, эргономичная мебель и не только.",
    },
  ],
];

const itemsFlat = items.reduce((acc, cur) => ([
  ...acc,
  ...cur,
]), []);

const Item = ({
  title,
  text,
  Icon,
  sx,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const itemRef = useRef();

  const handleItemMouseMove = (event) => {
    if (itemRef.current) {
      const { x, y } = itemRef.current.getBoundingClientRect();
      const { clientX, clientY } = event;
      const positionX = clientX - x;
      const positionY = clientY - y;
      setPosition({
        x: positionX,
        y: positionY,
      });
    }
  };

  return (
    <Box
      sx={{
        p: 8,
        color: "common.black",
        backgroundColor: "common.white",
        borderRadius: 6,
        ...sx,
      }}
      ref={itemRef}
      onMouseMove={handleItemMouseMove}
    >
      <Box
        sx={{
          mb: 4,
          position: "relative",
          "--positionX": position.x,
          "--positionY": position.y,
        }}
      >
        <Box
          component="svg"
          width="84px"
          height="80px"
          viewBox="0 0 84 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{
            transformOrigin: "center",
            transform: "rotate(calc(55deg - (var(--positionX) * 1deg)/10))",
          }}
        >
          <Box
            component="path"
            d="M62.0081 6.42766C75.9756 7.49 83.4303 23.397 75.3096 34.8108L49.3464 71.3028C41.1585 82.8111 23.5116 80.8366 18.0696 67.8033L1.48109 28.075C-3.69835 15.6706 6.05273 2.17184 19.4564 3.19128L62.0081 6.42766Z"
            fill="#1CB4FF"
            fillOpacity="0.12"
          />
        </Box>
        <Box
          component="svg"
          width="84px"
          height="80px"
          viewBox="0 0 84 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Box
            component="circle"
            cx="75.5"
            cy="8"
            r="3"
            stroke="#1CB4FF"
            strokeWidth="2"
            sx={{
              transform: "translate(calc((-100px + var(--positionX) * 1px) / -25), calc((-100px + var(--positionY)  * 1px) / 25))",

            }}
          />
          <Box
            component="circle"
            cx="11.5"
            cy="29"
            r="2"
            stroke="#1CB4FF"
            strokeWidth="2"
            sx={{
              transform: "translate(calc((-100px + var(--positionX) * 1px) / 30), calc((-100px + var(--positionY) * 1px) / -30))",
            }}
          />
          <Box
            component="rect"
            x="47.8977"
            y="70.1266"
            width="8px"
            height="8px"
            rx="1"
            // transform="rotate(-36.2354 47.8977 70.1266)"
            stroke="#1CB4FF"
            strokeWidth="2"
            sx={{
              transformOrigin: "56px 74px",
              transform: "translate(calc((-30px + var(--positionX) * 1px) / 50), calc((-30px + var(--positionY) * 1px) / -50)) rotate(calc(55deg - (var(--positionX) * 1deg)/10))",
            }}
          />
          {/* <rect x="47.8977" y="70.1266" width="8" height="8" rx="1" transform="rotate(-36.2354 47.8977 70.1266)" stroke="#1CB4FF" strokeWidth="2"/> */}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: "translate(calc((-100px + var(--positionX) * 1px) / 25), calc((-100px + var(--positionY) * 1px) / 25))",
            "& svg": {
              display: "block",
            }
          }}
        >
          <Icon />
        </Box>
      </Box>
      <Typography
        sx={{
          fontWeight: 800,
          fontSize: 20,
          mb: 1,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 16,
          lineHeight: 1.2,
          color: "grey.600"
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const Buns = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);

  const handlePaginationClick = (index) => {
    swiper.slideTo(index);
  };

  return (
    <Box
      sx={{
        width: "100%",

      }}
    >
      <Box
        sx={{
          height: 980,
          position: "sticky",
          top: 80,
          pointerEvents: "none",
          background: `
            url("/images/background/dashed-circles.svg") center / contain no-repeat
          `,
        }}
      />
      <Box
        component="section"
        id="perks"
        sx={{
          maxWidth: 624,
          width: "100%",
          paddingTop: 27,
          pb: 27,
          px: 4,
          mx: "auto",
          mt: "-980px",
          [theme.breakpoints.down("xs")]: {
            pt: 8,
            pb: 9,
          }
        }}
      >
        {isSmallScreen
          ? (
            <>
            
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              activeIndex={2}
              centeredSlides
              autoHeight
              onSwiper={(value) => setSwiper(value)}
              onSlideChange={(item) => {
                setCurrentSlide(item.activeIndex);
              }}

            >
              {items.map((list, index) => (
                <SwiperSlide
                  key={index}
                >
                  <Stack
                    spacing={4}
                  >
                    {list.map(({
                      id,
                      title,
                      text,
                      Icon,
                    }) => (
                      <Item
                        key={id}
                        title={title}
                        text={text}
                        Icon={Icon}
                      />
                    ))}
                  </Stack>
                </SwiperSlide>
              ))}
            </Swiper>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ p: 3 }}
            >
              {items.map((_, index) => (
                <Box
                  component="button"
                  sx={{
                    backgroundColor: "common.white",
                    height: 8,
                    width: 8,
                    borderRadius: "50%",
                    opacity: index === currentSlide ? 1 : 0.5,
                    border: 0,
                    p: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => handlePaginationClick(index)}
                />
              ))}
            </Stack>
          </>
          )
          : (
            <Masonry
              columnsCount={2}
              gutter="32px"
            >
              {itemsFlat.map(({
                id,
                title,
                text,
                Icon,
              }, index) => (
                <Item
                  key={id}
                  title={title}
                  text={text}
                  Icon={Icon}
                  sx={{
                    mt: index === 1 ? 14 : 0,
                  }}
                />
              ))}
            </Masonry>
          )}
      </Box>
    </Box>
  );
};