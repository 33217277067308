import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { IconHabr } from "../icons/Habr";
import { IconMail } from "../icons/Mail";
import { IconTelegram } from "../icons/Telegram";
import { theme } from "theme";

const links = [
  {
    Icon: IconHabr,
    href: "https://habr.com/ru/companies/postgrespro/profile/",
  },
  {
    Icon: IconMail,
    href: "mailto:job@postgrespro.ru",
  },
  {
    Icon: IconTelegram,
    href: "https://t.me/postgrespro",
  },
];

export const Footer = () => {
  return (
    <Box
      component="section"
      sx={{
        borderTop: "thin solid",
        borderColor: "grey.800",
        width: "100%",
      }}
    >
      <Stack
        flexWrap="wrap"
        direction={{ xs: "column",  sm: "row" }}
        sx={{
          maxWidth: "1072px",
          width: "100%",
          pt: 6,
          pb: 9,
          px: 4,
          mx: "auto",
          [theme.breakpoints.down("xs")]: {
            pb: 6,
          }
        }}
        spacing={4}
        justifyContent="space-between"
      >
        <Typography>
          Остались вопросы? Свяжитесь с нами по почте:
          {" "}
          <Box
            component="a"
            href="mailto:job@postgrespro.ru"
            sx={{
              color: "inherit",
              textDecoration: "none",
              fontWeight: "bold",
              display: "block",
              [theme.breakpoints.down("xs")]: {
                display: "inline",
              }
            }}
          >
            job@postgrespro.ru
          </Box>
        </Typography>
        <Stack
          direction="row"
          spacing={6}
        >
          {links.map(({
            Icon,
            href,
          }) => (
            <Box
              component="a"
              key={href}
              href={href}
              target="_blank"
              sx={{ color: "inherit" }}
            >
              <Icon
                sx={{
                  width: "unset",
                  fontSize: "1.5rem",
                }}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};