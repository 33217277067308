import React, { useState } from "react";
import { Box, Stack, Link } from "@mui/material";
import { Logo } from "components/Logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { theme } from "theme";

const links = [
  {
    title: "О компании",
    link: "#about",
  },
  {
    title: "Мы предлагаем",
    link: "#perks",
  },
  {
    title: "Вакансии",
    link: "#jobs",
  },
  {
    title: "Стажировки",
    link: "#internships",
  },
];

export const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
  return (
    <Box
      sx={{
        backgroundColor: "grey.900",
        borderBottom: "thin solid",
        borderColor: "rgba(255, 255, 255, 0.1)",
        zIndex: 2,
        position: "sticky",
        top: 0,
        left: 0,
      }}
      alignItems="center"
    >
      <Stack
        component="header"
        sx={{
          maxWidth: "1072px",
          width: "100%",
          py: 3,
          px: 4,
          mx: "auto",
          position: "relative",
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          component="a"
          href="/"
          sx={{
            fontSize: "4rem",
            color: "common.white"
          }}
        >
          <Logo />
        </Box>
        <nav>
          <ClickAwayListener
            onClickAway={() => {
              setIsOpened(false);
            }}
          >
            <div>
              <Stack
                component="ul"
                direction= {isSmallScreen ? "column" : "row"}
                spacing={6}
                sx={{
                  listStyle: "none",
                  m: 0,
                  p: 0,
                  [theme.breakpoints.down("sm")]: {
                    display: isOpened ? "block" : "none",
                    position: "absolute",
                    backgroundColor: "grey.900",
                    border: "thin solid",
                    borderColor: "rgba(255, 255, 255, 0.1)",
                    top: "100%",
                    right: 10,
                    padding: 6,
                    borderRadius: 3,
                  }
                }}
              >
                {links.map(({
                  title,
                  link
                }) => (
                  <li
                    key={link}
                  >
                    <Link
                      href={link}
                      sx={{
                        textTransform: "uppercase",
                        color: "common.white",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        setIsOpened(false);
                      }}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </Stack>
              <Box
                component="button"
                type="button"
                label="Закрыть"
                sx={{
                  p: 0,
                  border: "thin solid",
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  color: "common.white",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  height: 25,
                  width: 25,
                  borderRadius: "50%",
                  position: "relative",
                  [theme.breakpoints.up("md")]: {
                    display: "none"
                  },
                }}
                onClick={() => { setIsOpened((prev) => !prev) }}
              >
                <Box
                  sx={{
                    width: 15,
                    height: 2,
                    backgroundColor: "currentcolor",
                    borderRadius: 6,
                    position: "absolute",
                    left: 4,
                    top: "calc(50% - 1px)",
                    transform: isOpened
                      ? "rotate(45deg)"
                      : "translateY(-3px)",
                    transition: "all ease 0.2s",
                    transformOrigin: "center",
                  }}
                />
                <Box
                  sx={{
                    width: 15,
                    height: 2,
                    backgroundColor: "currentcolor",
                    borderRadius: 6,
                    position: "absolute",
                    left: 4,
                    top: "calc(50% - 1px)",
                    transform: isOpened
                      ? "rotate(-45deg)"
                      : "translateY(3px)",
                    transition: "all ease 0.2s",
                    transformOrigin: "center",
                  }}
                />
              </Box>
            </div>
          </ClickAwayListener>
        </nav>
      </Stack>
    </Box>
  );
};