import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { theme } from "theme";

export const Internships = () => {
  return (
    <Box
      component="section"
      id="internships"
      alignItems="center"
      direction="row"
      sx={{
        maxWidth: "1072px",
        width: "100%",
        pb: 43,
        px: 4,
        [theme.breakpoints.down("sm")]: {
          pb: 17,
        },
      }}
      spacing={25}
    >
      <Typography
        variant="h2"
        sx={{
          width: "100%",
        }}
      >
        Стажировки
      </Typography>
      <Box>
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: 390,
            width: "100%",
            maxWidth: 805,
            borderRadius: 8,
            mx: "auto",
            mb: 20,
            overflow: "hidden",
            position: "relative",
            [theme.breakpoints.down("xs")]: {
              height: 354,
            }
          }}
        >
          <Box
            component="img"
            alt="Олег Бартунов"
            src="/images/internships.png"
            sx={{
              width: 805,
              position: "absolute",
              bottom: -1,
              left: "49%",
              transform: "translateX(-50%)",
              [theme.breakpoints.down("xs")]: {
                width: 600,
              }
            }}
          />

        </Box>
        <Stack
          spacing={12}
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: "600px",
            mx: "auto",
          }}
        >

          <Typography
            sx={{
              textAlign: "center",
              fontSize: 24,
              [theme.breakpoints.down("sm")]: {
                fontSize: 16, 
              }
            }}
          >
            Хочешь стать специалистом вместе с POSTGRES PROFESSIONAL?  У нас есть несколько программ, которые могут тебе подойти:
          </Typography>
          <Button
            variant="outlined"
            component="a"
            target="_blank"
            href="https://pgstart.ru/"
            sx={{
              width: "100%",
              maxWidth: 310,
            }}
          >
            Программы стажировок
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};