import React from "react";
import { Stack, Typography, Button, Link } from "@mui/material";
import { IconArrowRight } from "icons/ArrowRight";
import { theme } from "theme";

export const Intro = () => {
  return (
    <Stack
      component="section"
      alignItems="center"
      sx={{
        maxWidth: "1072px",
        width: "100%",
        pt: 30,
        pb: 27,
        px: 4,
        [theme.breakpoints.down("xs")]: {
          pt: 8,
          pb: 9,
        }
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: 2,
        }}
      >
        POSTGRES PROFESSIONAL
      </Typography>
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          mb: 10,
          maxWidth: 600,
        }}
      >
        Разрабатываем Postgres Pro — самую популярную российскую СУБД
      </Typography>
      <Stack
        spacing={6}
        direction={{ xs: "column",  md: "row" }}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Button
          variant="contained"
          endIcon={<IconArrowRight />}
          sx={{
            maxWidth: "300px",
            width: "100%",
          }}
          component={Link}
          href="#jobs"
        >
          Смотреть вакансии
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "300px" }}
          component={Link}
          href="#internships"
        >
          Стажировки
        </Button>
      </Stack>
    </Stack>
  );
};