import { alpha } from "@mui/material";

export const MuiChip = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.common.white,
      fontSize: "1rem",
      borderRadius: 20,
      height: 40,
      "& .MuiChip-label": {
        paddingLeft: 24,
        paddingRight: 24,
      }
    }),
    outlined: ({ theme }) => ({
      borderColor: alpha(theme.palette.common.white, 0.4),
    }),
  },
};
