import React, { useMemo, useState } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Box, Stack, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Masonry from "react-responsive-masonry";
import { Swiper, SwiperSlide } from "swiper/react";
import { theme } from "theme";

SwiperCore.use([Pagination]);

const items = [
  [
    {
      id: 1,
      title: "70%",
      text: "российских разработчиков PostgreSQL работают у нас",
    },
    {
      id: 2,
      title: "№2",
      text: "в международном рейтинге разработчиков PostgreSQL. Обгоняем Amazon, Google, Apple и других",
    },
    {
      id: 3,
      title: "200+",
      text: "специалистов в команде",
    },
  ],
  [
    {
      id: 4,
      title: "РБК-500",
      text: "заказчики СУБД Postgres Pro",
    },
    {
      id: 5,
      title: "150.000+",
      text: "строк кода написали, больше 40 уникальных решений и фич внедрили",
    },
    {
      id: 6,
      title: "89%",
      text: "контрактов* приходится на СУБД Postgres Pro",
      subText: "*данные Госзакупок, 2022"
    },
  ],
  [
    {
      id: 7,
      title: "x 7,5 раз",
      text: "вырос бизнес Postgres Professional за последние 3 года",
    },
    {
      id: 8,
      title: "1200+",
      text: "постгресистов собираем ежегодно на конференции PGConf.Russia",
    },
    {
      id: 9,
      title: "200+ млн руб",
      text: "вложила компания в развитие российского PostgreSQL-сообщества",
    },
  ],
];

const itemsFlat = items.reduce((acc, cur) => ([
  ...acc,
  ...cur,
]), []);

const Item = ({
  title,
  text,
  subText,
}) => (
  <Box
    sx={{
      p: 8,
      color: "common.black",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      borderRadius: 6,
    }}
  >
    <Typography
      sx={{
        fontWeight: 800,
        fontSize: 40,
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 1.2,
      }}
    >
      {text}
    </Typography>
    {subText && (
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 12,
          mt: 3,
        }}
      >
        {subText}
      </Typography>
    )}
  </Box>
);

export const About = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const columnsCount = useMemo(() => {
    if (isSmallScreen) {
      return 1;
    }

    if (isMiddleScreen) {
      return 2;
    }

    return 3;
  }, [isSmallScreen, isMiddleScreen]);

  const handlePaginationClick = (index) => {
    swiper.slideTo(index);
  };

  return (
    <Box
      id="about"
      component="section"
      sx={{
        maxWidth: "1072px",
        width: "100%",
        pt: 27,
        pb: 27,
        px: 4,
        [theme.breakpoints.down("xs")]: {
          pt: 9,
          pb: 9,
        }
      }}
    >
      {isSmallScreen
        ? (
          <>
          
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            activeIndex={2}
            centeredSlides
            autoHeight
            onSwiper={(value) => setSwiper(value)}
            onSlideChange={(item) => {
              setCurrentSlide(item.activeIndex);
            }}

          >
            {items.map((list, index) => (
              <SwiperSlide
                key={index}
              >
                <Stack
                  spacing={4}
                >
                  {list.map(({
                    id,
                    title,
                    text,
                    subText,
                  }) => (
                    <Item
                      key={id}
                      title={title}
                      text={text}
                      subText={subText}
                    />
                  ))}
                </Stack>
              </SwiperSlide>
            ))}
          </Swiper>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ p: 3 }}
          >
            {items.map((_, index) => (
              <Box
                component="button"
                sx={{
                  backgroundColor: "common.white",
                  height: 8,
                  width: 8,
                  borderRadius: "50%",
                  opacity: index === currentSlide ? 1 : 0.5,
                  border: 0,
                  p: 0,
                  cursor: "pointer",
                }}
                onClick={() => handlePaginationClick(index)}
              />
            ))}
          </Stack>
         </>
        )
        : (
          <Masonry
            columnsCount={columnsCount}
            gutter="16px"
          >
            {itemsFlat.map(({
              id,
              title,
              text,
              subText,
            }) => (
              <Item
                key={id}
                title={title}
                text={text}
                subText={subText}
              />
            ))}
          </Masonry>
        )}
    </Box>
  );
};