import React from "react";

export const IconSport = (rest) => {
  return (
    <svg width="84" height="80" viewBox="0 0 84 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M59.2083 27.9036C56.5114 25.2081 52.9295 23.5804 49.1253 23.3216C45.321 23.0627 41.5518 24.1902 38.5145 26.4956C35.4773 28.8009 33.3777 32.1281 32.6039 35.8618C31.8301 39.5955 32.4346 43.4831 34.3055 46.8056L26.0809 55.0302C25.3079 55.8306 24.8801 56.9026 24.8898 58.0153C24.8995 59.128 25.3458 60.1924 26.1326 60.9793C26.9195 61.7661 27.9839 62.2124 29.0966 62.2221C30.2093 62.2318 31.2813 61.804 32.0817 61.031L35.9266 57.203L40.2469 52.8828C43.5798 54.8701 47.5281 55.5596 51.3373 54.8194C55.1464 54.0793 58.5493 51.9614 60.8955 48.8706C63.2416 45.7798 64.3666 41.9329 64.0554 38.065C63.7441 34.197 62.0185 30.5795 59.2083 27.9036ZM30.885 59.8257C30.4023 60.2755 29.7638 60.5203 29.1042 60.5087C28.4445 60.4971 27.8151 60.2298 27.3486 59.7633C26.8821 59.2968 26.6148 58.6674 26.6032 58.0077C26.5915 57.3481 26.8364 56.7096 27.2862 56.2269L30.5539 52.9592L34.1527 56.558L30.885 59.8257ZM40.6118 51.0325H40.5439L40.3827 50.9731H40.2299H40.0941H39.9328L39.7885 51.041L39.6442 51.1343H39.5848L35.341 55.3782L31.7422 51.7794L35.986 47.5356C35.986 47.5356 35.986 47.5356 35.986 47.4762C36.0556 47.3896 36.1101 47.2921 36.1473 47.1876C36.2139 46.9768 36.1956 46.7485 36.0964 46.551C36.0964 46.551 36.0964 46.5086 36.0964 46.4831C34.4646 43.8724 33.7369 40.7975 34.0254 37.7323L49.3626 53.1035C46.2975 53.392 43.2225 52.6642 40.6118 51.0325ZM58.0115 49.0294C56.1946 50.8501 53.9138 52.1387 51.4166 52.7555L34.3649 35.6953C34.8854 33.5864 35.8864 31.6264 37.2898 29.9685C38.6932 28.3105 40.4609 26.9995 42.4549 26.1378C44.4488 25.2762 46.615 24.8872 48.7841 25.0013C50.9533 25.1154 53.0667 25.7296 54.9592 26.7957C56.8518 27.8618 58.4722 29.351 59.6939 31.1471C60.9155 32.9431 61.7054 34.9973 62.0017 37.1492C62.2981 39.301 62.0929 41.4923 61.4022 43.5517C60.7115 45.6111 59.5539 47.4829 58.02 49.0209L58.0115 49.0294Z" fill="#1CB4FF" stroke="#1CB4FF" strokeWidth="2"/>
      <path d="M29.4414 29.6606C30.6165 29.6606 31.7652 29.3121 32.7422 28.6593C33.7193 28.0064 34.4808 27.0785 34.9305 25.9929C35.3802 24.9072 35.4978 23.7126 35.2686 22.5601C35.0393 21.4076 34.4735 20.3489 33.6425 19.518C32.8116 18.6871 31.753 18.1212 30.6005 17.892C29.448 17.6627 28.2533 17.7804 27.1677 18.2301C26.0821 18.6798 25.1541 19.4413 24.5013 20.4184C23.8485 21.3954 23.5 22.5441 23.5 23.7192C23.5 25.2949 24.126 26.8062 25.2402 27.9204C26.3544 29.0346 27.8656 29.6606 29.4414 29.6606ZM29.4414 19.4754C30.2807 19.4754 31.1012 19.7243 31.7991 20.1906C32.497 20.6569 33.0409 21.3197 33.3622 22.0952C33.6834 22.8706 33.7674 23.7239 33.6037 24.5471C33.4399 25.3704 33.0357 26.1265 32.4422 26.72C31.8487 27.3136 31.0925 27.7177 30.2693 27.8815C29.4461 28.0452 28.5928 27.9612 27.8173 27.64C27.0419 27.3188 26.3791 26.7748 25.9127 26.0769C25.4464 25.3791 25.1975 24.5586 25.1975 23.7192C25.1975 22.5937 25.6446 21.5142 26.4405 20.7184C27.2364 19.9225 28.3158 19.4754 29.4414 19.4754Z" fill="#1CB4FF" stroke="#1CB4FF" strokeWidth="2"/>
    </svg>
  );
}
