export const MuiInputBase = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: "rgba(255, 255, 255, 0.4)",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "thin solid",
        borderColor: theme.palette.common.white,
        borderRadius: 12,
      }
    }),
  },
};
