import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "theme";
import { IconDbms } from "../icons/Dbms";
import { IconOpenSource } from "../icons/OpenSource";
import { IconPeople } from "../icons/People";

const values = [
  {
    id: 0,
    Icon: IconDbms,
    title: "СТРОИМ СУБД-ОТРАСЛЬ",
    text: "Внедряем уникальные для рынка фичи и решения. Растим сообщество. Проводим конференции. Выпускаем курсы и книги. Организуем единственную в стране сертификацию специалистов по PostgreSQL.",
  },
  {
    id: 1,
    Icon: IconOpenSource,
    title: "РАЗВИВАЕМ OPEN SOURCE",
    text: "Ежегодно отправляем в мировое сообщество PostgreSQL более 100 патчей, в течение нескольких лет возвращаем большинство уникальных фич в открытую версию СУБД.",
  },
  {
    id: 2,
    Icon: IconPeople,
    title: "РАСТИМ ЭКСПЕРТИЗУ",
    text: "Опыт специалистов — главный драйвер роста компании. Обмениваемся знаниями, поддерживаем идеи, даем пространство для воплощения сложных и интересных проектов.",
  },
];

export const Values = () => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      component="section"
      sx={{
        maxWidth: "1072px",
        width: "100%",
        paddingTop: 27,
        pb: 27,
        px: 4,
        [theme.breakpoints.down("xs")]: {
          pt: 8,
          pb: 9,
        }
      }}
    >
      <Stack
        spacing={6}
        flexWrap="wrap"
        direction={{ xs: "column",  md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        {values.map(({
          id,
          Icon,
          title,
          text,
        }) => (
          <Stack
            key={id}
            alignItems="center"
            sx={{ maxWidth: isMiddleScreen ? null : 320 }}
          >
            <Icon
              sx={{
                fontSize: 50,
                mb: 6,
              }}
            />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                mb: 3,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              {text}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};