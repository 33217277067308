import React from "react";
import { Box, Typography } from "@mui/material";
import { theme } from "theme";
import { ContactForm } from "./ContactForm";

export const ContactUs = ({
  sx,
}) => {
  return (
    <Box
      component="section"
      alignItems="center"
      direction="row"
      sx={{
        maxWidth: "1072px",
        width: "100%",
        paddingTop: 27,
        px: 4,
        [theme.breakpoints.down("sm")]: {
          pb: 17,
        },
        ...sx
      }}
      spacing={25}
    >
      <Typography
        variant="h2"
        sx={{
          width: "100%",
        }}
      >
        Не нашли подходящую вакансию?
        <br />
        Cвяжитесь с нами
      </Typography>
      
      <ContactForm />
    </Box>
  );
};