import React from "react";
import { SvgIcon } from "@mui/material";

export const IconArrowRight = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 17 9"
      {...rest}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7556 5.13074C17.0815 4.77171 17.0815 4.22829 16.7556 3.86926L13.508 0.290313C13.3455 0.111295 13.1193 2.97082e-09 12.8702 0C12.6212 -2.969e-09 12.3949 0.111295 12.2325 0.290313C12.0631 0.476963 11.9881 0.727477 11.9881 0.921053C11.9881 1.11463 12.0631 1.36514 12.2325 1.55179L14.072 3.57895L0.88208 3.57895C0.63834 3.57895 0.404156 3.6721 0.234797 3.85874C0.0694351 4.04097 2.69766e-09 4.27378 0 4.5C-2.69766e-09 4.72622 0.0694351 4.95903 0.234797 5.14126C0.404156 5.3279 0.63834 5.42105 0.88208 5.42105L14.072 5.42105L12.2325 7.44821C11.9067 7.80724 11.9067 8.35065 12.2325 8.70969C12.3949 8.88871 12.6212 9 12.8702 9C13.1193 9 13.3455 8.88871 13.508 8.70969L16.7556 5.13074Z" />
    </SvgIcon>
  );
};