import { createTheme } from "@material-ui/core/styles";
// import { createMuiTheme } from "@material-ui/core/styles";
import { themeOptions } from "./theme-options";
import { components } from "./components";

export const theme = createTheme({
  ...themeOptions,
  // overrides: styleOverrides,
  components,
});
