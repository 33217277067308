import React, { useCallback } from "react";
import { FormHelperText, Box } from "@mui/material";
import { SmartCaptcha } from "@yandex/smart-captcha";

export const SubscriptionToCaptcha = ({
  error,
  onTokenChange,
  onStatusChange,
}) => {
  const handleChallengeVisible = useCallback(() => onStatusChange("visible"), [onStatusChange]);
  const handleChallengeHidden = useCallback(() => onStatusChange("hidden"), [onStatusChange]);
  const handleNetworkError = useCallback(() => onStatusChange("network-error"), [onStatusChange]);
  const handleSuccess = useCallback((token) => {
    onStatusChange("success");
    onTokenChange(token);
  }, [onStatusChange, onTokenChange]);
  const handleTokenExpired = useCallback(() => {
    onStatusChange("token-expired");
    onTokenChange("");
  }, [onStatusChange, onTokenChange]);

  return (
    <Box>
      <SmartCaptcha
        sitekey="ysc1_ZGgfGraJcw9c0FXMRskGAWwcOENiHRzqOSMOkAq64a910ef6"
        onChallengeVisible={handleChallengeVisible}
        onChallengeHidden={handleChallengeHidden}
        onNetworkError={handleNetworkError}
        onSuccess={handleSuccess}
        onTokenExpired={handleTokenExpired}
      />
      <FormHelperText
        error={Boolean(error)}
        sx={{
          ml: 3.5,
          mr: 3.5,
        }}
      >
        {error}
      </FormHelperText>
    </Box>
  );
};