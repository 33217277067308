import React from "react";
import { SvgIcon } from "@mui/material";

export const IconOpenSource = (rest) => {
  return (
    <SvgIcon
      viewBox="0 0 54 52"
      {...rest}
    >
      <path d="M17.3023 50.6763L18.1188 51L23.5243 37.4331L22.7043 37.1094C20.289 36.1449 18.2853 34.369 17.0375 32.0872C15.7896 29.8054 15.3757 27.1603 15.8668 24.6064C16.3579 22.0526 17.7233 19.7495 19.7284 18.0934C21.7335 16.4371 24.2531 15.5312 26.8533 15.5312C29.4541 15.5312 31.9733 16.4371 33.9786 18.0934C35.9837 19.7497 37.3491 22.0527 37.8403 24.6064C38.3313 27.1603 37.9174 29.8054 36.6696 32.0872C35.4217 34.3689 33.418 36.1447 31.0028 37.1094L30.1863 37.4366L35.6376 50.9721L36.4541 50.6484C42.2555 48.2954 47.002 43.9109 49.8069 38.3137C52.6116 32.7165 53.2831 26.2902 51.6953 20.2342C50.1078 14.1787 46.3702 8.90785 41.1802 5.40669C35.9902 1.90544 29.7034 0.41331 23.4935 1.20907C17.2839 2.00488 11.5763 5.03404 7.43679 9.73055C3.2974 14.4272 1.00954 20.4702 1.00012 26.7304C0.98451 31.9002 2.5331 36.9542 5.44254 41.228C8.35197 45.5015 12.4862 48.7955 17.3018 50.676L17.3023 50.6763ZM26.8046 2.74016C32.3107 2.72298 37.6547 4.60091 41.9397 8.05857C46.2248 11.516 49.1898 16.3426 50.3368 21.7279C51.4838 27.1132 50.7431 32.7288 48.239 37.6328C45.7348 42.5368 41.6196 46.4295 36.5844 48.6575L32.4493 38.3704C35.0941 37.0934 37.2267 34.9559 38.4976 32.3081C39.7685 29.6605 40.1024 26.6597 39.4442 23.797C38.7861 20.9347 37.1752 18.3811 34.8755 16.554C32.5758 14.7273 29.7241 13.7358 26.7868 13.7423C23.8499 13.7488 21.002 14.7527 18.7107 16.5896C16.419 18.4265 14.8194 20.9873 14.1738 23.8528C13.5283 26.718 13.8752 29.7175 15.1578 32.3594C16.4404 35.0013 18.5824 37.1297 21.2326 38.3948L17.136 48.703C12.0816 46.4948 7.94295 42.612 5.41686 37.7088C2.89076 32.8057 2.13181 27.1815 3.26799 21.7841C4.40399 16.3867 7.36564 11.5459 11.6539 8.07711C15.9422 4.60827 21.295 2.72324 26.8105 2.7403L26.8046 2.74016Z" stroke="currentColor"/>
    </SvgIcon>
  );
};