import React, { useEffect, useState } from "react";
import { Box, Typography, Chip, Stack, IconButton } from "@mui/material";
import { theme } from "theme";
import { IconChevronUp } from "icons/ChevronUp";
import { ContactForm } from "./ContactForm";

const ListItem = ({
  title,
  text,
  sx,
}) => {
  return (
    <Box
      sx={sx}
    >
      <Typography
        sx={{
          color: "primary.main",
          fontWeight: 800,
          fontSize: 20,
          mb: 4,
        }}
      >
        {title}
      </Typography>
      <Stack
        component="ul"
        sx={{
          m: 0,
          listStyleType: "'— '",
          pl: 7,
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Stack>
    </Box>
  );
};

const JobItem = ({
  isOpened,
  id,
  title,
  body,
  requirements,
  conditions,
  introduction,
  plus,
  onClick
}) => {
  return (
    <Box
      sx={{
        border: "thin solid",
        borderRadius: 6,
        borderColor: "common.white",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          px: 8,
          py: 6,
          [theme.breakpoints.down("sm")]: {
            p: 3,
          },
        }}
      >
        <Typography
          sx={{
            color: "primary.main",
            fontWeight: 800,
            fontSize: 32,
          }}
        >
          {title}
        </Typography>
        <IconButton
          sx={{
            color: "primary.main",
          }}
          size="large"
          onClick={onClick}
        >
          <IconChevronUp
            sx={{
              transform: !isOpened ? "rotate(180deg)" : "",
              transition: "transform ease 0.2s",
            }}
          />
        </IconButton>
      </Stack>
      {isOpened && (
        <Box
          sx={{
            px: 8,
            pb: 6,
            pt: 2,
            [theme.breakpoints.down("sm")]: {
              p: 3,
              pt: 2,
            },
          }}
        >
          {Boolean(introduction) && (
            <Typography
              component="div"
              sx={{ mb: 8 }}
              dangerouslySetInnerHTML={{ __html: introduction }}
            />
          )}
          {Boolean(body) && (
            <ListItem
              title="Чем предстоит заниматься:"
              text={body}
              sx={{ mb: 8 }}
            />
          )}
          {Boolean(requirements) && (
            <ListItem
              title="Мы ожидаем:"
              text={requirements}
              sx={{ mb: 8 }}
            />
          )}
          {Boolean(plus) && (
            <ListItem
              title="Будет плюсом:"
              text={plus}
              sx={{ mb: 8 }}
            />
          )}
          <ContactForm vacancy={id} />
        </Box>
      )}
    </Box>
  );
};

export const Jobs = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [list, setList] = useState([]);

  const loadList = async () => {
    try {
      const response = await fetch("/api/get_huntflow_vacancies");
      const data = await response.json();
      const result = data.vacancies
        .filter(
          ({ positions }) => (
            Array.isArray(positions) && positions.length > 0
          )
        );
      setList(result);
      setSelectedCategory(result[0]?.id);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadList();
  }, []);

  if (list.length === 0) {
    return null;
  }

  return (
    <Box component="section"
      id="jobs"
      alignItems="center"
      direction="row"
      sx={{
        maxWidth: "1072px",
        width: "100%",
        paddingTop: 27,
        pb: 27,
        px: 4,
        [theme.breakpoints.down("xs")]: {
          pt: 8,
          pb: 9,
        }
      }}
      spacing={25}
    >
      <Typography
        variant="h2"
        sx={{
          width: "100%",
        }}
      >
        Вакансии
      </Typography>
      <Stack
        flexWrap="wrap"
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          mb: 20,
        }}
      >
        {list.map(({
          id,
          title,
        }) => (
          <Box
            sx={{
              p: 2,
            }}
          >
            <Chip
              key={id}
              label={title}
              variant={selectedCategory === id ? "contained" : "outlined"}
              color={selectedCategory ? "primary" : "default"}
              onClick={() => setSelectedCategory(id)}
            />
          </Box>
        ))}
      </Stack>
      <Stack
        spacing={4}
      >
        {list?.find(
          (el) => el.id === selectedCategory
        )?.positions
          ?.map(({
            id,
            title,
            body,
            requirements,
            conditions,
            introduction,
            plus
          }) => {
            const isOpened = selectedJobs.includes(id);
            return (
              <JobItem
                key={id}
                id={id}
                isOpened={isOpened}
                title={title}
                body={body}
                requirements={requirements}
                conditions={conditions}
                introduction={introduction}
                plus={plus}
                onClick={() => { setSelectedJobs((prev) => {
                  if (isOpened) {
                    return prev.filter((item) => id !== item);
                  }
                  return [
                    ...prev,
                    id,
                  ];
                }); }}
              />
            );
          })}
      </Stack>
    </Box>
  );
};
;